import React, { useState, useRef } from 'react';
import Draggable from 'react-draggable';

import logo from './logo.svg';
import './App.css';

import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

import html2canvas from 'html2canvas';

import compas from './compas.svg';

function App() {

  const [showObject, setShowObject] = useState(true);
  const [showObject2, setShowObject2] = useState(false);
  const [hideSetting, setHideSetting] = useState(false);

  const handleSave = () => {
    // html2canvas(document.querySelector('.map__container')).then(function(canvas) {
    //   document.body.appendChild(canvas);
    // });
    htmlToImage.toPng(document.querySelector('#widget'))
      .then(function (dataUrl, test) {
        var img = new Image();
        img.src = dataUrl;
        document.body.appendChild(img);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  const handleHideSetting = () => {
    setHideSetting(!hideSetting);
  }

  const handleHideObject = () => {
    setShowObject(!showObject);
  }
  
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (selectedImage) {
      // Здесь вы можете отправить файл на сервер, используя fetch или другие методы
      console.log('Выбранное изображение:', selectedImage);
    }
  };
  
  const [selectedImage2, setSelectedImage2] = useState(null);

  const handleImageChange2 = (event) => {
    const file = event.target.files[0];

    if (file) {

      if(file.type === 'image/svg+xml'){
        console.log(file);
      };

      const reader = new FileReader();

      reader.onload = (e) => {
        setSelectedImage2(e.target.result);
        // document.getElementById("mySvg").appendChild(svgimg);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSubmit2 = (event) => {
    event.preventDefault();

    if (selectedImage2) {
      // Здесь вы можете отправить файл на сервер, используя fetch или другие методы
      console.log('Выбранное изображение:', selectedImage2);
    }
  };

  const [degPoli, setDegPoli] = useState(0);

  const [mapType, setMapType] = useState(true);

  return (
    <div className="map" id="widget">
        <div className="map__wrap">
            {selectedImage && (
              <div className='map__image'>
                <img src={selectedImage} alt="Preview" />
              </div>
            )}
            <iframe src="https://pkk.rosreestr.ru/#/search/44.88839772957234,34.100328194006565/17/@39eu618um" className={`map__iframe ${mapType && '_show' || ''} ${hideSetting ? '_hide-setting' : ''}`}></iframe>
            {!mapType && (
              <iframe src="https://egrp365.ru/p_map/" className={`map__iframe ${!mapType && '_show' || ''} ${hideSetting ? '_hide-setting' : ''}`}></iframe>
            )}
            <div className={`map__container ${hideSetting ? '_hide-setting' : ''}`}>
                <div className="map__compas">
                  <img src={compas}/>
                </div>
                <div className="map__info">
                  <div className="map__info-item">
                    <div className="map__info-item-symbol"></div>
                    <div className="map__info-item-content">
                      <div className="map__info-item-title" contentEditable={true}>Условные обозначения</div>
                      <div className="map__info-item-text" contentEditable={true}>Земельный участок</div>
                    </div>
                  </div>
                  {showObject2 && (
                    <div className="map__info-item">
                      <div className="map__info-item-symbol _blue"></div>
                      <div className="map__info-item-content">
                        <div className="map__info-item-title" contentEditable={true}>Условные обозначения</div>
                        <div className="map__info-item-text" contentEditable={true}>Земельный участок</div>
                      </div>
                    </div>
                  )}
                </div>
                <div className='map__buttons'>
                  <div>
                    {/* <button className='map__button-setting' onClick={handleHideSetting}>{hideSetting && 'Показать' || 'Спрятать'} настройки</button> */}
                    <button className='map__button-object' onClick={handleHideObject}>{showObject && 'Спрятать' || 'Показать'} прямоугольник</button>
                    <button className='map__button-object' onClick={e => {
                      setShowObject2(!showObject2);
                    }}>{showObject2 && 'Спрятать' || 'Показать'} прямоугольник 2</button>
                  </div>
                  <div>
                    <button className='map__button-object' onClick={e => {
                      setMapType(!mapType);
                    }}>Переключить карту</button>
                    {!selectedImage && (
                      <form onSubmit={handleSubmit}>
                        <input type="file" onChange={handleImageChange} accept="image/*" />
                        <button type="submit">Загрузить карту</button>
                      </form>
                    ) || (
                      <button onClick={e => {
                        setSelectedImage(null);
                      }}>Удалить картинку карту</button>
                    )}
                    
                  </div>
                  <div>
                    <input type="number" value={degPoli} onChange={e => {
                      setDegPoli(e.target.value);
                    }} />
                    {!selectedImage2 && (
                      <form onSubmit={handleSubmit2}>
                        <input type="file" onChange={handleImageChange2} accept="image/*" />
                        <button type="submit">Загрузить полилинии</button>
                      </form>
                    ) || (
                      <button onClick={e => {
                        setSelectedImage2(null);
                      }}>Удалить полилинии</button>
                    )}
                    
                  </div>
                </div>
                <Draggable>
                  <div 
                    className="map__object"
                    style={{
                      opacity: showObject ? 1 : 0
                    }}
                  ></div>
                </Draggable>
                <Draggable>
                  <div 
                    className="map__object _blue"
                    style={{
                      opacity: showObject2 ? 1 : 0
                    }}
                  ></div>
                </Draggable>
                {selectedImage2 && (
                  <Draggable>
                    <div className='map__polilinii'>
                      <img 
                        src={selectedImage2} width="100px" height="auto" alt="Preview" 
                        style={{
                          transform: 'rotate('+degPoli+'deg)'
                        }}
                      />
                    </div>
                  </Draggable>
                )}
                <div className="map__size" contentEditable={true}>1:5000</div>
            </div>
        </div>
    </div>
  );
}

export default App;
